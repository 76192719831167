import React, { useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { InputBase, Box, Typography, Avatar } from "@material-ui/core";
import axios from "axios";
import { sortAddress } from "src/utils";
import { useHistory } from "react-router-dom";
import Apiconfigs from "src/Apiconfig/Apiconfigs";
import NoDataFounds from "../../component/NoDataFounds";

export default function SearchBox({
  search,
  searchIcon,
  inputRoot,
  inputInput,
}) {
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState();
  const [isloading, setisloading] = useState(false);

  const history = useHistory();
  const searchHandler = async (cancelTokenSource) => {
    try {
      setisloading(true);
      const res = await axios.post(
        Apiconfigs.globalSearch,
        {
          search: searchText,
        },
        {
          cancelToken: cancelTokenSource && cancelTokenSource.token,
        }
      );
      if (res.data.responseCode == 200) {
        setSearchResult(res.data.result);
        setisloading(false);
      } else {
        setSearchResult();
        setisloading(false);
      }
    } catch (error) {
      console.log("ERROR", error);
      setSearchResult();
      setisloading(false);
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (searchText != "") {
      searchHandler(cancelTokenSource);
    } else {
      setSearchResult();
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, [searchText]);

  return (
    <div className={"searchField customSearch"}>
      <div className={search}>
        <div className={searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search.."
          classes={{
            root: inputRoot,
            input: inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
        />
      </div>
      {searchResult && (
        <SearchResults
          searchResult={searchResult}
          history={history}
          setSearchText={(text) => setSearchText(text)}
        />
      )}
    </div>
  );
}

export function SearchResults({ searchResult, history, setSearchText }) {
  console.log("searchResult", searchResult);
  const [isloading, setisloading] = useState(false);
  return (
    <ul className="list-group text-dark" id="search-list">
      {searchResult?.collection?.length > 0 && (
        <>
          <li
            className="list-group-item"
            style={{ textAlign: "left", zIndex: 999 }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box display={"flex"}>
                <Typography variant="h6">Collection's</Typography>
              </Box>
            </Box>
          </li>
          {searchResult?.collection?.map((data, i) => {
            return (
              <li
                key={i}
                className="list-group-item"
                style={{ textAlign: "left", zIndex: 999 }}
                onClick={() => {
                  setSearchText("");
                  history.push({
                    pathname: "/collection",
                    search: data._id,
                    state: {
                      name: data?.name,
                      image: data?.baseURI,
                    },
                  });
                }}
              >
                <Box display={"flex"} justifyContent="space-between">
                  <Box display={"flex"}>
                    {console.log("lsdjlksdjf", data)}
                    <img src={data.image} alt="" />
                    {/* <Typography> {data.name}</Typography> */}
                  </Box>
                </Box>
              </li>
            );
          })}
        </>
      )}

      {searchResult?.nft?.length > 0 && (
        <>
          <li
            className="list-group-item"
            style={{ textAlign: "left", zIndex: 999 }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box display={"flex"}>
                <Typography variant="h6">NFT's</Typography>
              </Box>
            </Box>
          </li>
          {searchResult?.nft?.map((data, i) => {
            console.log("daasdfasdf", data?.nftDetails?.thumbnail);

            return (
              <li
                key={i}
                className="list-group-item"
                style={{ textAlign: "left", zIndex: 999 }}
                onClick={() => {
                  setSearchText("");
                  history.push({
                    pathname: "/nft-details",
                    search: data._id,
                  });
                }}
              >
                <Box display={"flex"} justifyContent="space-between">
                  <Box display={"flex"}>
                    {console.log("lsdjlkssfsdjf", data)}
                    {data?.nftDetails?.mimetype === "video/mp4" ? (
                      <Avatar style={{ height: "20px", width: "20px", marginRight: "10px" }}>
                        <video
                          loop={true}
                          autoPlay={true}
                          playsinline="true"
                          muted={true}
                          controls={false}
                          style={{ width: "100%" }}
                        // className={classes.myVideo}
                        >
                          <source
                            src={
                              data?.nftDetails?.thumbnail
                                ? data?.nftDetails?.thumbnail
                                : data?.thumbnail
                            }
                            type="video/mp4"
                          />
                        </video>
                      </Avatar>
                    ) : (
                      data?.nftDetails?.mimetype === "audio/mpeg" ?
                        <Avatar style={{ height: "20px", width: "20px", marginRight: "10px" }}>
                          <audio
                            loop={false}
                            autoPlay={false}
                            muted={true}
                            style={{ width: "100%" }}
                            controls
                          >
                            <source
                              src={
                                data?.nftId?.thumbnail
                                  ? data?.nftId?.thumbnail
                                  : data?.thumbnail
                              }
                              type="audio/mpeg"
                            />
                          </audio>
                        </Avatar>
                        :
                        <img src={data?.nftDetails?.thumbnail} alt="" />
                    )}
                    <Typography> {data?.nftDetails?.tokenName}</Typography>
                  </Box>
                </Box>
              </li>
            );
          })}
        </>
      )}

      {searchResult.collection.length === 0 &&
        searchResult.nft.length === 0 &&
        searchResult.user.length === 0 && (
          <>
            <NoDataFounds />
          </>
        )}
    </ul>
  );
}
