import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import TopBar from "./TopBar";
import { UserContext } from "src/context/User";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
}));
const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);
  return (
    <div className={classes.root}>
      <TopBar />
      {user?.userLoggedIn && (
        <div
          style={
            history.location.pathname !== "/"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <Header />
        </div>
      )}
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
