import React, { createContext, useEffect, useState } from "react";
import { injected, SUPPORTED_WALLETS,  } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import {
  contractKovan,
  NetworkContextName,
  ACTIVE_NETWORK,
  NetworkDetails,
} from "src/constants";
import { getWeb3ContractObject, getWeb3Obj, getContract } from "src/utils";
import Apiconfigs from "src/Apiconfig/Apiconfigs";
import { toast } from "react-toastify";
import GenerativeNFTABI from "src/constants/ABI/GenerativeNFTABI";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};
const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
    console.log(token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, account, deactivate, library, chainId } = useWeb3React();
  const [profile, setprofile] = useState([]);
  const [nftPrice, setNftPrice] = useState(0);
  const [userNFtLoading, setUserNftLoading] = useState(false);
  const [MAX_NFT_SUPPLY, setMAX_NFT_SUPPLY] = useState(0);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [generativeidc, setGenerativecon] = useState("");
  const [generativeid, setGenerative] = useState("");
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [MAX_NFT_WALLET, setMAX_NFT_WALLET] = useState(0);
  const [userNFTList, setUserNFTList] = useState([]);

  const [userLoggedIn, setuserLoggedIn] = useState(false);
  let data = {
    profile,
    nftPrice,
    totalSupply,
    MAX_NFT_WALLET,
    balanceOfValue,
    MAX_NFT_SUPPLY,
    userLoggedIn,
    generativeid,
    generativeidc,
    updateUser: (account) => {
      setSession(account);
    },
    updateprofile: (data) => {
      setprofile(data);
    },
    callprofile: () => {
      myprofile();
    },
    updatetoken: (token) => {
      setTokenSession(token);
    },
    connectWallet: (data) => connectWalletHandler(data),
    disconnectWallet: (data) => disconnectWalletHandler(data),
  };

  const connectWalletHandler = (data) => {
    try {
      const connector = data.connector;

      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          window.sessionStorage.removeItem("walletName");
          toast.error(JSON.stringify(error.message));
          window.sessionStorage.removeItem("walletName");
          activate(connector);
        }
      });
    } catch (error) {
      toast.error(JSON.stringify(error.message));
    }
  };
  const disconnectWalletHandler = (data) => {
    deactivate();
    window.sessionStorage.removeItem("walletName");
    toast.success("Disconnected");
  };

  const myprofile = async () => {
    await axios({
      method: "GET",
      url: Apiconfigs.myprofile,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    })
      .then(async (res) => {
        if (res.data.responseCode === 200) {
          console.log(res.data.result);
          data.updateprofile(res.data.result);
          setuserLoggedIn(true);
        } else {
          setuserLoggedIn(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setuserLoggedIn(false);
      });
  };

  const connecting = async (id) => {
    await axios({
      method: "POST",
      url: Apiconfigs.connectWallet,
      data: {
        walletAddress: id,
      },
    })
      .then(async (res) => {
        if (res.data.responseCode === 200) {
          data.updatetoken(res.data.result.token);
          myprofile();
        } else {
          console.log("error");
          setuserLoggedIn(false);
        }
      })
      .catch((error) => {
        setuserLoggedIn(false);
        console.log(error.message);
      });
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem("walletName")
      );
      connectWalletHandler(selectectWalletDetails[0].data);
    }
  }, []);
  const getContractDetailsHandler = async () => {
    try {
      setIsLoadingData(true);
      const web3 = await getWeb3Obj();
      const contractObj = await getWeb3ContractObject(
        GenerativeNFTABI,
        contractKovan
      );
      console.log("contractObj", contractObj);

      const MAX_NFT_WALLET = await contractObj.methods.MAX_NFT_WALLET().call();
      setMAX_NFT_WALLET(Number(MAX_NFT_WALLET));
      setIsLoadingData(false);
    } catch (err) {
      console.log(err);
      setIsLoadingData(false);
    }
  };

  const getCurrentMintingDetails = async () => {
    const contractObj = await getWeb3ContractObject(
      GenerativeNFTABI,
      contractKovan
    );
    const MAX_NFT_SUPPLY = await contractObj.methods.MAX_NFT_SUPPLY().call();
    setMAX_NFT_SUPPLY(Number(MAX_NFT_SUPPLY));
    const totalSupply = await contractObj.methods.totalSupply().call();
    setTotalSupply(Number(totalSupply));
    console.log("totalsupply", totalSupply);
  };

  const userNFTListHadler = async (balanceOf) => {
    setUserNFTList([]);
    setUserNftLoading(true);
    const contract = getContract(
      contractKovan,
      GenerativeNFTABI,
      library,
      account
    );

    try {
      for (let i = 0; i < balanceOf; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());
        console.log("filterdata", filter);
        const res = await axios.get(filter);
        if (res.status === 200) {
          setUserNFTList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
          setUserNftLoading(false);
        }
      }
    } catch (e) {
      console.log(e);
      setUserNftLoading(false);
    }
  };

  useEffect(() => {
    if (balanceOfValue > 0) {
      userNFTListHadler(balanceOfValue);
    }
  }, [balanceOfValue, account]);

  useEffect(() => {
    getContractDetailsHandler();
    getCurrentMintingDetails();
  }, []);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  useEffect(() => {
    data.updateUser(account);
    if (account) {
      connecting(account);
    } else {
      setuserLoggedIn(false);
    }
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
