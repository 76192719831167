// import React from 'react'
import React, { useEffect, useContext } from "react";
import {
  Typography,
  Box,
  makeStyles,
  FormHelperText,
  IconButton,
  Button,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
// import ButtonCircularProgress from "./ButtonCircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import { GiCancel } from "react-icons/gi";
import { UserContext } from "src/context/User";
import ErrorIcon from "@material-ui/icons/Error";
import { SUPPORTED_WALLETS } from "src/connectors";
const useStyles = makeStyles((theme) => ({
  walletBox: {
    background: "#FFFFFF",
    border: "1px solid #E9E9E9",
    boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "25px",
    padding: "30px",
    textAlign: "center",
    cursor: "pointer",
    // marginBottom:"50px",
    border: "1px solid transparent",
    minHeight: "185px",
    // height:"100%",
    "&:hover": {
      border: "1px solid #0D8CCD",
    },
    "& img": {
      marginBottom: "15px",
      maxWidth: "100%",
    },
    "& h5": {
      fontWeight: "600",
      fontSize: "22px",
      lineHeight: "33px",
      color: "#000000",
      marginBottom: "10px",
    },
    "& lavel": {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#3D3D3D",
    },
  },
  paper: {
    overflowY: "unset",
  },
  customizedButton: {
    position: "absolute",
    top: "-42px",
    right: "-9px",
    color: "#fff",
  },
  dialogBox: {
    padding: "30px",
  },
}));

const DialogBox = (props) => {
  const [checkedTerms, setCheckedTerms] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangecheckedTerms = (event) => {
    setCheckedTerms(event.target.checked);
  };

  const {
    data,
    onWalletConnectHandler,

    open2,
    setOpen2,
  } = props;
  return (
    <Box>
      <Box>
        <Dialog
          open={open2}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box className="modal_text">
            <Typography variant="h5" align="center">
              Fungy Terms Of Service
            </Typography>
            <Typography variant="body2" style={{ marginLeft: "10px" }}>
              Please take a few minutes to read and understand Fungy terms of
              services to continue all need to accept the terms of services by
              checking the box
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="age"
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="I am atleast 13 year old"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="terms"
                    onChange={handleChangecheckedTerms}
                    color="primary"
                  />
                }
                label="I accept the fungy Terms of Services"
              />
            </FormGroup>
          </Box>
          <DialogActions>
            <Button
              style={{
                backgroundColor: "#039be3",
                color: "#fff",
                width: "80px",
                maxWidth: "100%",
                borderRadius: "50px",
                height: "40px",
              }}
              onClick={handleClose2}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            {checked && checkedTerms && (
              <Button
                style={{
                  backgroundColor: "#039be3",
                  color: "#fff",
                  width: "80px",
                  maxWidth: "100%",
                  borderRadius: "50px",
                  height: "40px",
                }}
                disabled={!checked && !checkedTerms}
                onClick={() => onWalletConnectHandler(data)}
                color="primary"
                autoFocus
              >
                Proceed
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default DialogBox;
