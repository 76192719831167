import { UserContext } from "src/context/User";
import {
  Button,
  Dialog,
  DialogContent,
  Box,
  Typography,
  Link,
  ListItem,
  List,
  DialogContentText,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import React, { useContext, useEffect } from "react";
import { SUPPORTED_WALLETS } from "src/connectors";

export default function ConnectWallet({ open, handleClose }) {
  const user = useContext(UserContext);
  const { account } = useWeb3React();

  useEffect(() => {
    if (account && user?.userLoggedIn) {
      handleClose();
    }
  }, [account && user?.userLoggedIn]);

  return (
    <Dialog open={open} onClose={handleClose} className='walletdailog'>
      <Box textAlign='center' mt={2} mb={2}>
        <Typography variant='h3' style={{ color: "#fff" }}>
          Connect wallet
        </Typography>
      </Box>
      <DialogContent className='loginModal'>
        <DialogContentText id='alert-dialog-description'>
          <Box className='walletlist'>
            <List>
              {SUPPORTED_WALLETS.map((item, i) => {
                return (
                  <ListItem>
                    <Link
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.sessionStorage.removeItem("walletName");
                        window.sessionStorage.setItem("walletName", item.name);
                        user.connectWallet(item.data);
                      }}
                    >
                      <Typography variant='h3'>{item.data.name}</Typography>
                      <img src={item.data.iconName} alt={item.data.name} />
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </DialogContentText>
      </DialogContent>

      <Box textAlign='center' mt={2} mb={2}>
        <Button onClick={handleClose} variant='contained' color='primary'>
          Close
        </Button>
      </Box>
    </Dialog>
  );
}
