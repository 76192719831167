import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  logoImage: {
    "& img": {
      "@media(max-width:767px)": {
        width: "100px",
      },
    },
  },
  mainlogo: {
    "@media(max-width:767px)": {
      display: "none"
    },
  },
  responsivelogo: {
    "@media(min-width:767px)": {
      display: "none"
    },
  },
}));

const Logo = (props) => {
  const classes = useStyles();
  // return <img src="/images/logo.png" alt="Logo" {...props} />;
  return (
    <Box className={classes.logoImage}>
      <Box className={classes.responsivelogo}>
        <img
          src="images/_Group_ (1) (2).png"
          width="100%"
          alt="Logo"
          {...props}
          style={{
            height: "45px",
            marginTop: "8px",
            marginLeft: "-27px"
          }}
        />
      </Box>
      <Box className={classes.mainlogo}>
        <img
          src="images/logo1.png"
          width="100%"
          alt="Logo"
          {...props}
          style={{ height: "70px" }}
        />
      </Box>
    </Box>
  );
};

export default Logo;
