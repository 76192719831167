import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  InputBase,
  Drawer,
  Grid,
  Link,
  MenuItem,
  Box,
  Container,
  List,
  Typography,
  FormGroup,
  fade,
  FormControlLabel,
  Menu,
  Snackbar,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import DialogBox from "./DialogBox";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ListItem } from "@material-ui/core";
import { UserContext } from "src/context/User";
import { Alert } from "@material-ui/lab";
import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";
import SearchBox from "./SearchBox";
import ConnectWallet from "src/component/ConnectWallet";
const headersData = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Marketplace",
    href: "/marketplace",
  },
  {
    label: "Physical Nft",
    href: "/physicalnft",
  },
  // {
  //   label: "Mint",
  //   href: "/nftmint",
  // },
  {
    label: "Support",
    href: "/FAQ",
  },

  // {
  //   label: "Create",
  //   href: "/create",
  // },
];

const useStyles = makeStyles((theme) => ({
  header: {
    height: "68px",
    position: "absolute",
    backgroundColor: "#000000",
    paddingBottom: "6rem",
    // backgroundColor: "rgb(169 40 235)",

    paddingRight: "0",
    paddingLeft: "0px",
    "@media (max-width: 1280px)": {
      paddingLeft: "0",
      paddingRight: "0px",
      height: "70px",
      paddingTop: "5px",
    },
    "@media (max-width: 900px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "70px",
      paddingTop: "5px",
    },
    "@media (max-width: 767px)": {
      paddingBottom: "5rem",
    },
    "@media (max-width: 600px)": {
      paddingBottom: "4rem",
    },
  },
  logoHeader: {
    paddingTop: "7px",
    paddingBottom: "7px",
    "@media(max-width:1024px)": {
      paddingTop: "0rem",
      paddingBottom: "0",
    },
  },

  menuButton: {
    fontSize: "13px",
    height: "48px",
    fontWeight: "400",
    borderRadius: 0,
    marginLeft: "16px",
    minWidth: "auto",
    color: "#fff",
    // padding: "10px 24px",
    textTransform: "uppercase",
    letterSpacing: "1px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "1.75",
    },
    "&:active": {
      color: "#fff",
    },
    "&:last-child": {
      backgroundColor: "#1ed760",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    marginTop: "1rem",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "200px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
  },
  // drop: {
  //   color: "#000",
  // },

  drawericon: {
    color: "#000",
    position: "absolute",
    top: "20px",
    right: "0px",
    fontSize: "25px",
    "@media(max-width:767px)": {
      top: "9px",
    },
  },
  logoImg: {
    width: "100%",

    margin: " 1px 15px 1px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 1px 15px 1px 0px",
      width: "52px",
      // height: "44.5px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    marginTop: "0px",
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "36px",
    marginTop: "7px",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 20,
    width: "160px",
    maxWidth: "257px",
    marginLeft: "-20px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "257px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    // color: "inherit",
    fontSize: "16px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  box2: {
    marginTop: "20px",
    marginBottom: "40px",
  },
  box3: {
    marginBottom: "30px",
  },
}));

export default function Header() {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { activate, account, deactivate, chainId } = useWeb3React();
  const auth = useContext(UserContext);
  console.log("authsldf", auth?.profile?.email);
  const [errmsg, seterrmsg] = React.useState("");
  const [errpopup, seterrpopup] = React.useState("");
  const [checkedTerms, setCheckedTerms] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [errhandleClose, seterrhandleClose] = React.useState(false);
  const assessToken = window.sessionStorage.getItem("token");
  const [emailpopup, setemailpopup] = useState(true);
  const [commignmint, setCommignmint] = useState(false);
  const handleClosebutton = () => {
    setemailpopup(false);
  };
  useEffect(() => {
    if (window.location.pathname === "/profile") {
      setemailpopup(false);
    }
  }, [window.location.pathname === "/profile"]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangecheckedTerms = (event) => {
    setCheckedTerms(event.target.checked);
  };

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    // setOpen(    // window.sessionStorage.removeItem("token");
    // window.sessionStorage.removeItem("userAddress");true);
    // location.reload();
  };
  // useEffect(() => {
  //   if (
  //     window.sessionStorage.getItem("token") &&
  //     window.sessionStorage.getItem("userAddress")
  //   ) {
  //     history.push("/");
  //   }
  // }, [
  //   window.sessionStorage.getItem("token"),
  //   window.sessionStorage.getItem("userAddress"),
  // ]);
  const [DataDisconnect, setDataDisconnect] = useState("initialState");

  const disconnectHandler = () => {
    // setOpen(true);
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("userAddress");
    // href = "/";
    // window.sessionStorage.removeItem("userAddress");
    // location.pathname("/");
    seterrhandleClose(true);
    seterrpopup(true);
    seterrmsg("Disconnect Successful");
    setDataDisconnect("");
    window.location.href = "/";
    // history.push("/");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    logoHeader,
    data,
    onWalletConnectHandler,
    header,
    menuMobile,
    menuButton,
    toolbar,
    drawerContainer,
    drawericon,
    logoImg,
    logoDrawer,
    containerHeight,
    search,
    searchIcon,
    mainHeader,
    inputInput,
    inputRoot,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1264
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg">
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <Grid
          container
          item
          direction="row"
          justify="flex-end"
          style={{
            paddingLeft: "0px",
          }}
        >
          <SearchBox
            search={search}
            inputRoot={inputRoot}
            inputInput={inputInput}
            searchIcon={searchIcon}
          />

          {getMenuButtons()}
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "11px",
              marginRight: "19px",
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "13px",
                letterSpacing: "1px",
                cursor: "pointer",
              }}
              onClick={() => setCommignmint(true)}
            >
              MINT
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "11px",
              marginRight: "19px",
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "13px",
                letterSpacing: "1px",
                cursor: "pointer",
              }}
              onClick={() =>
                !auth?.userLoggedIn ? setOpen(true) : history.push("/create")
              }
            >
              CREATE
            </Typography>
          </Box>

          {auth?.userLoggedIn ? (
            <Button
              variant="contained"
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              Profile
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={() => setOpen(true)}
            >
              Connect
            </Button>
          )}
          {/* <Button variant="contained" size="small" color="secondary" onClick={handleClickOpen}>
        Connect
      </Button> */}
        </Grid>
      </Toolbar>
      // {/* </Container> */}
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo1.png" alt="logo" />

            {getDrawerChoices()}
            <Box
              style={{
                display: "flex",
                justifyContent: "start",
                width: "100%",
                borderBottom: "1px solid #3e3e3e",
              }}
            >
              <Button
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "400px",
                  display: "flex",
                  justifyContent: "start",
                  width: "100%",
                  paddingLeft: "16px",
                  height: "60px",
                }}
                onClick={() =>
                  !auth?.userLoggedIn ? setOpen(true) : history.push("/create")
                }
              >
                Create
              </Button>
            </Box>
            {/* {!auth?.userLoggedIn ? (
              <Link className="MobileButton" onClick={handleClickOpen}>
                Connect
              </Link>
            ) : (
              <Link
                onClick={disconnectHandler}
                href="/"
                className="MobileButton"
              >
                Disconnect
              </Link>
            )} */}
            <Box style={{ padding: "15px" }}>
              {auth?.userLoggedIn ? (
                <Button
                  variant="contained"
                  color="primary"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  Profile
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => setOpen(true)}
                >
                  Connect
                </Button>
              )}
            </Box>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}>
            <SearchBox
              search={search}
              inputRoot={inputRoot}
              inputInput={inputInput}
              searchIcon={searchIcon}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon width="60px" height="60px" style={{ color: "#fff" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Link
            {...{
              component: RouterLink,
              to: href,
              color: "inherit",
              style: { textDecoration: "none" },
              key: label,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Link>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box className={logoHeader}>
      <RouterLink style={{ color: "white" }} to="/">
        <Logo className={logoImg} />
      </RouterLink>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: RouterLink,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        className={header}
        elevation={0}
        style={
          history.location.pathname !== "/"
            ? { backgroundColor: "rgb(31 7 103)" }
            : { backgroundColor: "transparent" }
        }
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        {/* <MenuItem style={{ backgroundColor: "purple" }}>
          <RouterLink
            style={{ color: "white" }}
            // onClick={history.push("/")}
            to="/wallet"
          // link={component}
          >
            Wallet
          </RouterLink>
        </MenuItem> */}
        <MenuItem style={{ backgroundColor: "purple" }}>
          <RouterLink
            style={{ color: "white" }}
            // onClick={history.push("/")}
            to="/profile"
            // link={component}
          >
            My Profile
          </RouterLink>
        </MenuItem>
        <MenuItem style={{ backgroundColor: "purple" }}>
          <RouterLink style={{ color: "white" }} to="/nft">
            My Nft
          </RouterLink>
        </MenuItem>
        <MenuItem
          onClick={disconnectHandler}
          style={{ backgroundColor: "purple", color: "#fff" }}
        >
          Disconnect
        </MenuItem>
      </Menu>

      <Dialog
        open={open}
        className="walletdailog"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="dailogTitle">
          {"Terms and Condition"}
        </DialogTitle>
        <DialogContent className="loginModal" style={{ width: "100%" }}>
          <DialogContentText id="alert-dialog-description">
            <Box className="walletlist">
              <Box className={classes.box2}>
                {" "}
                <Typography>
                  Please take a few minutes to read and understand terms of
                  services to continue all need to accept the terms of services
                  by checking the box
                </Typography>
              </Box>
              <Box className={classes.box3}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="age"
                        onChange={handleChange}
                        color="primary"
                        checked={checked}
                      />
                    }
                    label="I am atleast 13 year old"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="terms"
                        onChange={handleChangecheckedTerms}
                        color="primary"
                        checked={checkedTerms}
                      />
                    }
                    label="I accept the Terms of Services"
                  />
                </FormGroup>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dailogFooter">
          {checked && checkedTerms && (
            <Button
              style={{
                backgroundColor: "#039be3",
                color: "#fff",
                width: "80px",
                maxWidth: "100%",
                borderRadius: "50px",
                height: "40px",
              }}
              onClick={() => {
                setOpen1(true);
                setOpen(false);
              }}
              color="primary"
              autoFocus
            >
              Proceed
            </Button>
          )}
          <Button
            onClick={() => {
              handleClose();
              setChecked(false);
              setCheckedTerms(false);
            }}
            color="Secondry"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {open1 && (
        <ConnectWallet open={open1} handleClose={() => setOpen1(false)} />
      )}

      <Dialog
        open={account && chainId !== ACTIVE_NETWORK}
        minWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ width: "atuo !important" }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Netwok Change Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box pb={3}>
              <Typography>Please switch to {NetworkContextName}</Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {auth?.userLoggedIn && chainId === ACTIVE_NETWORK ? (
        <Dialog
          open={
            window.location.pathname === "/profile"
              ? emailpopup
              : !auth?.profile?.email
          }
          onClose={
            window.location.pathname === "/profile" ? handleClosebutton : ""
          }
          minWidth="xs"
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "atuo !important" }}
          className="mainwidth"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography style={{ fontSize: "20px" }}>
                  Please update your profile first &nbsp;
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "7px",
                }}
              >
                <Button
                  onClick={() => {
                    history.push({
                      pathname: "/profile",
                      search: "openprofile",
                    });
                  }}
                >
                  Edit Profile
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
      <Box className="dialogsec">
        <Dialog
          open={commignmint}
          onClose={() => setCommignmint(false)}
          minWidth="xs"
          fullWidth
          style={{ width: "atuo !important" }}
          className="dialogsec1"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ background: "#381b5d" }}
          ></DialogTitle>
          <DialogContent style={{ background: "#381b5d" }}>
            <DialogContentText id="alert-dialog-description">
              <Box
                pb={3}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h1">Coming Soon</Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
      <Snackbar
        open={errpopup}
        autoHideDuration={5000}
        onClose={errhandleClose}
      >
        <Alert onClose={errhandleClose} severity="info">
          {errmsg}
        </Alert>
      </Snackbar>
    </>
  );
}
