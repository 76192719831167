import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  typosec: { textAlign: "center", color: "#222222" },
}));

export default function NoDataFound() {
  const classes = useStyles();
  return (
    <Box>
      <Typography className={classes.typosec}>No Data Found</Typography>
    </Box>
  );
}
