import React, { useContext } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Link,
  makeStyles,
  Button,
  FormGroup,
  DialogContentText,
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  FormControlLabel,
} from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";

import TelegramIcon from "@material-ui/icons/Telegram";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {} from "react-feather";
import { UserContext } from "src/context/User";
import ConnectWallet from "src/component/ConnectWallet";
import Checkbox from "@material-ui/core/Checkbox";

import Logo from "./../../component/Logo";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    // background: "#3d217d",

    backgroundColor: "#22266d",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#ececec",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  contentLink: {
    cursor: "pointer",
    margin: "8px 5px",
    padding: "5px 10px !important",
    border: "1px solid white",
    borderRadius: "20px",
    whiteSpace: "nowrap",
  },
  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  imgLogo: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    "& img": {
      width: "200px",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const auth = useContext(UserContext);
  const history = useHistory();
  const { activate, account, deactivate, chainId } = useWeb3React();

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [checkedTerms, setCheckedTerms] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangecheckedTerms = (event) => {
    setCheckedTerms(event.target.checked);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Box className={classes.footerSection}>
        <Box>
          <Container maxWidth="lg">
            <Grid
              container
              style={{ color: "white" }}
              justify="center"
              spacing={1}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box className={classes.imgLogo}>
                  {/* <Logo width="100" /> */}
                  <img src="images/logo1.png" alt="" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={1}>
                  {/* <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box className="sociaLinks">
                      <List style={{ padding: "0px" }}>
                        <ListItem style={{ padding: "5px 0px 0px 0px" }}>
                          <Link
                            href="https://www.facebook.com/"
                            target="_blank"
                          >
                            <FacebookIcon />
                          </Link>
                          <Link
                            // href="https://web.telegram.org/z/"
                            href="https://t.me/dendomains"
                            target="_blank"
                          >
                            <TelegramIcon />
                          </Link>
                          <Link
                            //  href="https://twitter.com/"
                            href="https://twitter.com/dendomains"
                            target="_blank"
                          >
                            <TwitterIcon />
                          </Link>
                          <Link
                            href="https://www.instagram.com/accounts/login/?hl=en"
                            target="_blank"
                          >
                            <InstagramIcon />
                          </Link>
                        </ListItem>
                      </List>
                    </Box>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <List
                      className="listingFooter"
                      style={{
                        // width: "41%",
                        padding: "5px 0px 0px 0px",
                      }}
                    >
                      <ListItem
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // alignItems: "center",
                          lineHeight: "25px",
                          // justifyContent: "space-evenly",
                          flexWrap: "wrap",
                        }}
                      >
                        <Link
                          onClick={() =>
                            !auth?.userLoggedIn
                              ? setOpen(true)
                              : history.push("/profile")
                          }
                          className={classes.contentLink}
                        >
                          My Profile
                        </Link>
                        <Link
                          onClick={() =>
                            history.push({
                              pathname: "/showStaticContent",
                              // state: data,
                              search: "Terms & Conditions",
                            })
                          }
                          className={classes.contentLink}
                        >
                          Terms and Conditions
                        </Link>
                        <Link
                          onClick={() =>
                            history.push({
                              pathname: "/showStaticContent",
                              // state: data,
                              search: "PrivacyPolicies",
                            })
                          }
                          className={classes.contentLink}
                        >
                          Privacy and Policy
                        </Link>

                        <Link
                          onClick={() =>
                            history.push({
                              pathname: "/showStaticContent",
                              // state: data,
                              search: "AboutUs",
                            })
                          }
                          className={classes.contentLink}
                        >
                          About Us
                        </Link>
                        <Link
                          onClick={() => history.push("/contactus")}
                          className={classes.contentLink}
                        >
                          Contact Us
                        </Link>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box className="lowerFooter">
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                      color: "white",
                    }}
                  >
                    copyright 2022 | NFT Marketplace
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Dialog
        open={open}
        className="walletdailog"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="dailogTitle">
          {"Terms and Condition"}
        </DialogTitle>
        <DialogContent className="loginModal" style={{ width: "100%" }}>
          <DialogContentText id="alert-dialog-description">
            <Box className="walletlist">
              <Box className={classes.box2}>
                {" "}
                <Typography>
                  Please take a few minutes to read and understand terms of
                  services to continue all need to accept the terms of services
                  by checking the box
                </Typography>
              </Box>
              <Box className={classes.box3}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="age"
                        onChange={handleChange}
                        color="primary"
                        checked={checked}
                      />
                    }
                    label="I am atleast 13 year old"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="terms"
                        onChange={handleChangecheckedTerms}
                        color="primary"
                        checked={checkedTerms}
                      />
                    }
                    label="I accept the Terms of Services"
                  />
                </FormGroup>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dailogFooter">
          {checked && checkedTerms && (
            <Button
              style={{
                backgroundColor: "#039be3",
                color: "#fff",
                width: "80px",
                maxWidth: "100%",
                borderRadius: "50px",
                height: "40px",
              }}
              onClick={() => {
                setOpen1(true);
                setOpen(false);
              }}
              color="primary"
              autoFocus
            >
              Proceed
            </Button>
          )}
          <Button
            onClick={() => {
              handleClose();
              setChecked(false);
              setCheckedTerms(false);
            }}
            color="Secondry"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {open1 && (
        <ConnectWallet open={open1} handleClose={() => setOpen1(false)} />
      )}

      {/* <Dialog
        open={account && chainId !== ACTIVE_NETWORK}
        minWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ width: "atuo !important" }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Netwok Change Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box pb={3}>
              <Typography>
                Please switch to {NetworkContextName}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
    </>
  );
}
