import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { VscBrowser } from "react-icons/vsc";

import {} from "react-feather";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  headerSection: {
    // background: "rgba(34,196,254)",
    background: "linear-gradient(77deg, #e264 30%,  #8205af)",
    // background:
    //   "linear-gradient(29deg, rgba(34,196,254,1) 0%, rgba(0,89,236,1) 100%, rgba(0,212,255,1) 100%)",
    top: "101px",
    position: "absolute",
    width: "100%",
    left: "0",
    zIndex: "9",
    maxWidth: "100%",
    overflow: "auto",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    "& .secondaryHeader": {
      // [theme.breakpoints.down("xs")]:{
      //   display:"block"
      // },
      justifyContent: "start",
    },
  },

  iconText: {
    width: 14,
    height: 14,
    marginRight: "8px",
    color: "#fff",
  },
  iconBox: {
    display: "flex",
    "& p:hover": {
      // "& :hover": {
      color: "#1f0767",
      // },
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const history = useHistory;
  return (
    <>
      <Box className={classes.headerSection}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Box className="secondaryHeader">
              <Link to="/my-collection">
                <Box className={classes.iconBox}>
                  <VscBrowser className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Pop Arts Collection
                  </Typography>
                </Box>
              </Link>
              <Link to="/nft">
                <Box className={classes.iconBox}>
                  <VscBrowser className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Pop Arts I Made
                  </Typography>
                </Box>
              </Link>
              <Link to="/view-collections">
                <Box className={classes.iconBox}>
                  <VscBrowser className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Collections
                  </Typography>
                </Box>
              </Link>
              {/* <Link href="/my-auction">
                {" "}
                <Box className={classes.iconBox}>
                  <VscBrowser className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Pop Arts - Show Builder
                  </Typography>
                </Box>
              </Link>

              <Link href="/wallet">
                <Box className={classes.iconBox}>
                  <VscBrowser className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    My Wallet
                  </Typography>
                </Box>
              </Link>
              <Link href="/account-review">
                <Box className={classes.iconBox}>
                  <VscBrowser className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Review
                  </Typography>
                </Box>
              </Link>
              <Link href="/favourites">
                <Box className={classes.iconBox}>
                  <VscBrowser className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Favourites
                  </Typography>
                </Box>
              </Link> */}
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
